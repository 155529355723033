@font-face {
  font-family: 'rex-logo';
  src: url('assets/fonts/apercu-regular-pro.d5a65842.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}
* {
  font-family: 'rex-logo';
}
body {
  background-color: rgb(241, 232, 215);
}
.card__image-container {
  width: 100%;
  padding-top: 100%;
  border-radius: 5px;
  background-color: #f3f4f6;
  overflow: hidden;
  position: relative;
}
.card__image-container-dark {
  width: 100%;
  padding-top: 100%;
  border-radius: 5px;
  background-color: #111;
  border-bottom: 1px solid #7773;
  overflow: hidden;
  position: relative;
}
.card__image-container:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
}

.card__image-container-dark:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
}

.card-image {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 600px) {
  .card-image {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
  }
}
.card__content {
  padding: 0;
  position: relative;
}

.card__title {
  margin-bottom: 20px;
}

.card__info {
  display: flex;
  align-self: end;
  align-items: center;
}

.card__price {
  margin-left: auto;
  padding: 5px 20px;
  background-color: #ffffff;
  border-radius: 20px;
}

.hero {
  position: relative;
  background-color:#111;
  height: 100vh;
  padding: 40px;
}

.main-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
}

.secondary-logos {
  position: absolute;
  top: calc(50% - 120px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.secondary-logo {
  max-width: 80px;
}

.side-logos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.side-logos.top {
  top: calc(50% - 240px);
}

.side-logos.bottom {
  top: calc(50% + 140px);
}

.side-logos.left {
  top: 50%;
  left: calc(50% - 240px);
  flex-direction: column;
}

.side-logos.right {
  top: 50%;
  left: calc(50% + 140px);
  flex-direction: column;
}

.side-logo {
  max-width: 60px;
}

.MuiSelect-select {
  white-space: break-spaces !important;
}

.css-1jh78f5-MuiPaper-root-MuiDialog-paper {
  display: inline-flex !important; 
  flex-direction: column !important;
  padding: 1rem !important;
}


.title2:before,
.title2:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 100px; /* Adjust the width of the lines as needed */
  border-top: 1px solid #940000; /* Adjust the line style as needed */
}

.title2:before {
  right: 100%; /* Line on the left side */
  margin-right: 10px; /* Adjust the spacing between the line and the text */
}

.title2:after {
  left: 100%; /* Line on the right side */
  margin-left: 10px; /* Adjust the spacing between the line and the text */
}

.img1 {
  position: absolute;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 700;
  top: 10%;
  left: 35%;
}
.img2 {
  position: absolute;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 700;
  top: 50%;
  left: 0;
}
.img3 {
  position: absolute;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 700;
  top: 50%;
  right: 10%;
}
.img4 {
  position: absolute;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 700;
  top: 90%;
  left: 35%;
}
.icon1 {
  position: absolute;
  fill: #940000;
  font-weight: bolder;
  font-size: 72px;
  top: 100%;
  transform: rotate(180deg);
  left: 71%;
}
.icon2 {
  position: absolute;
  fill: #940000;
  font-weight: bolder;
  font-size: 72px;
  top: 20%;
  left: 73%;
  transform: rotate(90deg);
}
.icon3 {
  position: absolute;
  fill: #940000;
  font-weight: bolder;
  font-size: 72px;
  top: 20%;
  left: 13%;
}
.icon4 {
  position: absolute;
  fill: #940000;
  font-weight: bolder;
  font-size: 72px;
  top: 100%;
  transform: rotate(-90deg);
  left: 11%;
}