@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

* {
  margin: 0;
  padding: 0;
}

img {
  animation: fadein 2s;
}
  @keyframes fadein {
    from { opacity: 0.1; filter: blur(4px) }
    to   { opacity: 1; filter: blur(0) }
  }


.react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap>div {
    display: flex !important;
    justify-content: center !important;
}

.styles-module_image__2hdkJ {
  max-height: 80% !important;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  z-index: 2500 !important;
}
